import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import video1 from '../../assets/videos/postopservice.mp4'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import download_logo from '../../assets/images/projects/service/postop/downloadicon.png'
import postop_logo from '../../assets/images/projects/service/postop/postop-logo.png'
import nhs_logo from '../../assets/images/projects/service/postop/nhs-logo.png'
import adobelogo from '../../assets/images/projects/service/mercedes/adobe_logo.png'
// import pic02 from '../../assets/images/projects/service/bsmart/19.png'
import pic04 from '../../assets/images/projects/24.png'
import resume from '../../assets/CV/post-op-casestudy.pdf'
import { useRef } from 'react'





const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTecne = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
    <Layout>
      <Helmet>
        <title>Service Design - Post-Op</title>
        <meta name="Service Design - Post-Op" content="Service Design" />
      </Helmet>

      <div id="main" className="alt">
        <section id="work-banner">
          <div>
            <span className="image main">
              <Img fluid={props.data.tecnecover.childImageSharp.fluid} alt="" />
            </span>
          </div>
        </section>

        <div className="project-wrapper">
          <div className="project-content">
            <header>
              <p className="maisontags">AI-enabled, Research, UX Strategy, Service Design, User Experience, Digital UX/UI, Brand Idendity</p>

              <h1>
              From Pain to Progress; how AI enhanced patient recovery experience and clinical workflow efficiency.
                {/* Improving post-op recovery outcomes with AI-assisted monitoring. */}
              </h1>
              <h3>
              {/* We empowered patients to confidently manage their post-op recovery, 
              knowing their clinical teams are tracking their progress. Patients 
              reported a greater peace of mind, while clinical teams found the 
              system intuitive and effective in strengthening patient relationships. */}
              We designed a holistic service experience and digital solution that 
              empowers patients to confidently manage their post-op recovery, 
              knowing their clinical teams are tracking their progress with the 
              help of a digital patient management system.
              <br />
              <br />
              Patients experienced improved recovery outcomes and greater peace of 
              mind, while clinical teams found the system intuitive and effective 
              in building trust and strengthening patient relationships.
              </h3>
              <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneoutcome53.childImageSharp.fluid} />
              </div>
              </section>

              {/* <h3>
              Our holistic service experience and digital solution enable 
              patients to control their recovery via a mobile app after 
              leaving the hospital. The digital patient management system 
              allows clinical staff and support teams to monitor patient 
              progress remotely and intervene in person if needed.
              </h3> */}


              {/* <h1>Post op</h1>

              <h3>
                We assessed the current pain points in the recovery of post-operative
                patients of elective hip & knee replacement surgery.
              </h3>
              <br />
              <h3>
                We developed an holistic service experience and digital solution
                that allows patients, after leaving the hospital, to have control
                over their recovery process remotely via a mobile app.
              </h3>
              <br />
              <h3>
                The digital patient management system (desktop, tablet & mobile) also enables
                clinical staff (doctors and nurses) and support teams (physiotherapists,
                nutritionists, etc) to monitor patient outcomes remotely and proactively
                intervene in-person if required.
              </h3> */}

            </header>

            <section className="header-post">
              <div className="headerpost">
                {/* <div className="headerpost-left">

                  <p>
                    A summarised version of the project is presented here.
                    <br /> For more detailed information, feel free to download the case study document for this project.
                    <br />
                    <div className="resume-download">
                      <a href={resume} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
                        📘 Download case study</a>
                    </div>
                  </p>
                </div> */}

                {/* <div className="headerpost-right">
                  <div className="client-image">
                    <img className="span-4" src={things_logo} alt="things logo" />
                  </div>
                  <br />
                  <p>
                    This project was carried out while working at the design agency, THINGS, in Milan.
                    <br />
                    <br />
                    <a href="/about-things"><u>know more about THINGS</u></a>
                  </p>
                </div> */}
              </div>
            </section>


            <div className="process">
              <div className="process-left">
                <h4>
                  Project context
                </h4>
                <span className="credits-text">
                Two experienced orthopedic surgeons, each with over 20 years in the NHS, 
                approached us to address a pressing challenge impacting hospitals and
                 patients undergoing hip and knee replacement surgeries.
                 </span>
                 <br />
                
                  
                <span className="credits-text">
                    Their vision was to create a product that not only addressed 
                    this issue but also inspired broader changes in healthcare practices, 
                    benefiting patients regardless of where they had their surgery.
                    </span>
                {/* <p>
                  It started when two Orthopaedic surgeons with more than
                  20 years of experience in the NHS (UK) and private sector
                  approached us with a problem they had observed faced by 
                  patients & hospitals after surgery.
                  </p> 
                  <p>
                  They want to create a product that would not only solve the 
                  problem but also inspire a change in healthcare practice across the industry,
                   no matter where the patient had their surgery.
                </p> */}
                
              </div>

              <div className="process-right">
              <h4>
              Industry              
              </h4>
              <span className="credits-text">
              HealthTech
              </span>
              <br />
              <h4>
              My role
              </h4>
              <span className="credits-text">
              The project lead, 🔍 the Researcher, 🎯 the UX Strategist, ✍️ the Service Experience Designer and 📱the UX/UI Designer.
              <br />
              <br />
              I collaborated with OPD subject matter experts, Doctors, Patients, Clinician teams, Front & Back-end Developers, and Service & Visual Designer.
              </span>
              <br />
              <div className="challenge-client">
                  {/* <br /> */}
                  <h5>Client</h5>
                  <div className="client-image">
                    <img className="span-3" src={postop_logo} alt="bsmart logo" />
                    <img className="span-3" src={nhs_logo} alt="bsmart logo" />
                  </div>
                </div>
              </div>
            </div>


            <section className="challenge-section">
              {/* <span className="project-step-number">01</span>
              <h2>the challenge</h2> */}
              

              <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecnechallenge2.childImageSharp.fluid} />
              </div>
              </section>

              <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecnechallenge.childImageSharp.fluid} />
              </div>
              </section>

              <div className="process">
                <div className="process-left">
                  <p className="half-textlarge">
                  Patients were dissatisfied with poor recovery outcomes after surgery.
                  </p>
                  <p>
                  Orthopedic surgery patients frequently experienced inadequate post-op care during the critical first 30 days, 
                  compounded by lengthy waiting lists for follow-up doctor visits.
                  </p>
                </div>
                <div className="process-right">
                  <p className="half-textlarge">
                  Hospitals faced an overload of patients and rising care delivery costs.
                  </p>
                  <p>
                  Frequent post-surgery visits by patients increased costs and 
                  placed additional strain on healthcare systems, ultimately affecting the quality of care.
                  </p>
                </div>
                </div>

              {/* <div className="challenge">
                <div className="challenge-desc">

                <p className="half-textlarge">
                Post surgery wound infections & complications are the 3rd leading cause of death worldwide.
                </p>

                  <p>
                  Orthopedic surgery patients frequently face inadequate 
                  pre and post-op care, resulting in dissatisfaction and 
                  poor recovery outcomes.
                  </p>

                  <p>They often return to healthcare providers multiple 
                    times post-surgery, increasing costs and burdening
                    healthcare systems, ultimately impacting care quality.
                  </p>
                  

                  <p>
                  There is no easily accessible global standardised 
                  follow up routine for surgical patients - and as a 
                  result, wound infections and complications make 
                  surgery the 3rd greatest contributor to deaths worldwide.
                  </p>

                  <p>
                    The experience Patients undergoing orthopaedic surgery went
                    through pre and post operation was poor. This had resulted
                    in dissatisfied patients and poor recovery outcomes causing
                    them to visit their care giver and hospital multiple times
                    post surgery.
                  </p>

                  <p>
                    This in turn increased the cost and burden
                    on the healthcare system, hampering their provision of
                    good quality care.
                  </p>

                </div>

              </div> */}

              <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>

            </section>


            <section className="process-section">
              {/* <span className="project-step-number">02</span>
              <h2>THE APPROACH</h2> */}

              

              <section className="fullspan">
                  <div className="image">
                    <Img fluid={props.data.tecneprocess21.childImageSharp.fluid} />
                  </div>
              </section>

              <div className="changebg">

                <div className="process">
                  {/* <div className="process-left">
                    <h4>Understanding the pre & post surgical context</h4>
                  </div> */}
                  <div className="process-right">
                    <p>
                    We aimed to understand the surgical context and the patient's experience during hip and knee replacement surgery.
                    </p> 
              
                    {/* <p className="half-textlarge">
                    To gain a deeper understanding of the problem, we stepped into the 
                    shoes of our users: patients, doctors, nurses, and support staff.
                    </p> */}

                    {/* <p className="half-textlarge">
                      To understand the problem better we stepping into our user’s shoes
                      — patients, doctors, nurses & other support staff.
                    </p> */}

                  </div>
                </div>


                <div className="image">
                  <Img fluid={props.data.tecneprocess2.childImageSharp.fluid} />
                </div>

                <p className="full-textlarge">
                Our findings were surprising, even for the surgeons and practitioners involved.
                <br />
                <br />
                The problem did not lie within the surgery itself but rather in the pre- and post-operative care, which directly contributed to poor recovery outcomes.             
                 </p>

                 <div className="image">
                  <Img fluid={props.data.tecneprocess22.childImageSharp.fluid} />
                </div>

                <div className="process">
                <div className="process-left">
                {/* <p className="half-textlarge">
                We wanted to learn about the patients' physical & emotional journey; and the role played by clinical teams, their internal workflows, KPIs & SOPs.
                </p> */}
                <p className="half-textlarge">
                To better understand the care ecosystem, we shifted our research focus.
                {/* We wanted to learn about the people, products, places, and processes involved in the care ecosystem; before, during & after surgery.  */}
                </p>
                </div>

                  <div className="process-right">
                    {/* <p>
                    Patients start from different points and follow various paths, making their journeys complex and varied. 
                    <br />
                    <br />
                    We seeked to link current clinical team workflows and constraints with the patient experience and expectations.
                    </p> */}
                    {/* <h4>
                    Key stakeholders —
                    </h4>
                    <p>
                    Identify the various actors, their roles and interactions within the clinical team and with the patients.
                    </p> */}
                    <p>
                    We explored the people, processes, and tools involved in pre and post-surgery care.
                    </p>
                    <h4>
                    Patient's current journey —
                    </h4>
                    <p>
                    We examined patients' physical and emotional experiences, understanding their challenges and specific needs.
                    </p>
                    <h4>
                    Clinical Team's workflows, SOPs & KPIs —
                    </h4>
                    <p>
                    We analysed their daily tasks, tools used, constraints faced, and specific needs of clinical teams to optimize workflows, SOPs, and KPIs.
                     </p>
                    
                    
                    {/* <p>
                      We wanted to learn about:
                    </p>
                    <ol>
                      <li>The actors surrounding the patient from pre-op to post-op.</li>
                      <li>The patient’s journey, emotions and challenges they face before undergoing the operation and whilst recovering post operation.</li>
                      <li>The day-to-day tasks of clinical teams, the constraints and  challenges the face.</li>
                      <li>The needs and expectations of patients.</li>
                      <li>The needs of the clinical teams to unlock better care provision.</li>
                    </ol> */}

                  </div>
                </div>
              



              {/* <p className="full-textlarge">
                Current patient & clinical team journey post surgery was mapped out.
                <br />
                <br />
                We were able to identify — Pain points, Gaps in the experience,
                Areas for journey enhancement, Opportunities for touchpoints
                to improve interaction.
              </p> */}
                <div className="image">
                  <Img fluid={props.data.tecneprocess23.childImageSharp.fluid} />
                </div>

                
                    
                <h4>
                    We synthesised our research findings and found that —
                </h4>

                <div className="process">
                <div className="process-left">
                
                <p className="half-textlarge">
                Patients experienced neglect, anxiety, and confusion upon returning home.
                  </p>
                  {/* <h4>Defining the UX Strategy and service value proposition</h4> */}
                </div>
                <div className="process-right">
                <p className="half-textlarge">
                The clinical team lacked remote visibility into patients' statuses.
                  </p>
                </div>
              </div>
              </div>
                
              <section className="fullspan">
                <div className="image">
                  <Img fluid={props.data.tecneprocess24.childImageSharp.fluid} />
                </div>
                </section>

              {/* <div className="process">
              <div className="process-left">
              <h4>Synthesising our conversations and observations</h4>
              </div>
              <div className="process-right">
              <p>Our research allowed us to map out holistically the current experience of the patients and the clinical teams.</p>  
              </div>
              </div>  */}

              {/* <div className="image">
                <Img fluid={props.data.tecneprocess3.childImageSharp.fluid} />
              </div> */}

              {/* <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess3a.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess3b.childImageSharp.fluid} />
            </Slider> */}

              {/* <p className="full-textlarge">
                Pain points, gaps in the experience,
                areas for journey enhancement, and opportunities for touchpoints
                to improve interaction were identified.
              </p> */}


              {/* <div className="process">
                <div className="process-left">
                  <p>
                    Things current hip & knee operation patients do:
                  </p>
                  <ol>
                    <li>Experience pain and need an operation</li>
                    <li>Attend a new-patient clinic for diagnosis</li>
                    <li>Attend a pre-op clinic</li>
                    <li>Attend a joint school</li>
                    <li>Undergo operation</li>
                    <li>Stay in hospital ward for few days</li>
                    <li>Recover from operation at home</li>
                    <li>Go back to the hospital for a follow-up visit at an out-patient clinic</li>
                  </ol>
                </div>
                <div className="process-right">
                  <p>
                    Current tasks of hip & knee replacement doctors:
                  </p>
                  <ol>
                    <li>Do an operation</li>
                    <li>visit out-patient clinics (new patient or follow-up patient)</li>
                    <li>Make ward rounds to check on patients that are at the hospital post surgery</li>
                    <li>Do admin/paperwork (insert patient recovery data from paper to a digital national database)</li>
                  </ol>
                </div>
              </div> */}






                {/* <div className="process">
                  <div className="process-left">
                    <h4>Insights</h4>
                  </div>
                  <div className="process-right">
                    <p className="half-textlarge">
                      Synthesising our conversations and observations to identify symptoms and root causes.
                    </p>
                  </div>
                </div> */}

                <section className="fullspan">
                <div className="image">
                  <Img fluid={props.data.tecneprocess4.childImageSharp.fluid} />
                </div>
                </section>

                <div className="process">
                <div className="process-left">
                
                <p className="half-textlarge">
                I guided them through the current journey map of patients and clinicians, which was rooted in research.
                {/* We wanted to learn about the people, products, places, and processes involved in the care ecosystem; before, during & after surgery.  */}
                </p>
                </div>

                  <div className="process-right">
                    <p>
                    In reality, patients varied in their starting points, understanding of their issues, and often had unmet expectations.
                    </p>
                    <p>
                    Our message remianed simple: Embrace patient input; don't exclude them from communication with clinicians. 
                     </p>
                     
                     <p>
                     We found support in a doctor who 
                     understood the shifting patient expectations and advocated with us for patient-led care. 
                     </p>
                    
                  </div>
                </div>

                

                <div className="changebg">

                <div className="image">
                  <Img fluid={props.data.tecneprocess25.childImageSharp.fluid} />
                </div>

                <div className="bluebg">
            <p className="full-textlargebluebg">
            How might we empower clinical teams to prioritize care for those in need, 
            ensuring early treatment to prevent wound infections and complications?
            </p>
            </div>

              

                <div className="image">
                  <Img fluid={props.data.tecneprocess26.childImageSharp.fluid} />
                </div>

                <div className="image">
                  <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
                </div>

                <div className="process">
                <div className="process-left">
                
                <p className="half-textlarge">
                For patients, recovery is a journey. <br /><br />
                For clinical teams, it's enablement.
                </p>
                </div>

                  <div className="process-right">

                    <p>
                    After surgery, the responsibility of collecting recovery data shouldn't rest solely on clinicians — it should be a partnership with patients.
                    </p>
                    <p>
                    Post Op empowers patients to actively engage in their recovery by proactively reporting on their wound status. 
                    </p>
                    <p>
                    This approach eliminates conflicts of interest in gathering vital information, especially within the critical first 30 days post-surgery.
                    </p>
                    
                     {/* <p>
                     The service blueprint allowed us to identify points in the journey where a digital touchpoint was required to complete a specific task (jobs to be done) by patients and the clinical teams.
                     </p>
                     <p>
                     We also defined the offline journey for the patients and workflows for the clinical teams.
                     </p> */}
                    
                  </div>
                </div>

                

                <div className="image">
                  <Img fluid={props.data.tecneprocess8.childImageSharp.fluid} />
                </div>
                </div>

                <div className="bluebg">
            <p className="full-textlargebluebg">
            Patients should be motivated and enabled to easily report their recovery status remotely. 
            <br /><br />
            Clinicians need to seamlessly integrate preventive tracking of recovery outcomes into their workflow and establish open & transparent communication with their team and patients.
            </p>
            </div>


                

              {/* <div className="image">
                <Img fluid={props.data.tecneprocess6.childImageSharp.fluid} />
              </div> */}

              {/* <div className="image">
                <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
              </div> */}


              {/* <Slider className="image-scroll" {...settings}>
              <Img fluid={props.data.tecneprocess7.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess5.childImageSharp.fluid} />
              <Img fluid={props.data.tecneprocess8.childImageSharp.fluid} />
            </Slider> */}
              
              



              {/* <p className="full-textlarge">
                Recovery experience of patients now vs. the envisioned end-to-end experience with post op
              </p> */}

              {/* <div className="image">
                <Img fluid={props.data.tecneprocess8.childImageSharp.fluid} />
              </div> */}


              {/* <div className="changebg">
                <div className="process">
                  <div className="process-left">
                    <h4>Benchmarking</h4>
                  </div>
                  <div className="process-right">
                    <p>
                      How Post Op compares to other medical or surgical journey companions
                    </p>
                  </div>
                </div>
                <div className="image">
                  <Img fluid={props.data.tecneprocess9.childImageSharp.fluid} />
                </div>

                <p className="full-textlarge">
                  Our main goal was to achieve the most universal product for both patients and clinicians.
                </p>
              </div> */}



                <section className="fullspan">
                <div className="image">
                  <Img fluid={props.data.tecneprocess27.childImageSharp.fluid} />
                </div>
                </section>

                <section className="fullspan">
                <div className="image">
                  <Img fluid={props.data.tecneprocess28.childImageSharp.fluid} />
                </div>
                </section>



                <div className="process">
                <div className="process-left">
                <p className="half-textlarge">
                Empowering family members as Caregivers.
                </p>
                </div>
                <div className="process-right">
                
                <p>During our hospital visits, I noticed that family members often felt powerless and wished they could contribute to their loved one's recovery.</p>
                   <p>
                   This spurred us to explore ways to empower them to participate in the care journey through tools and education.
                   </p>
                   
                </div>
                </div>

                <div className="changebg">
                <div className="process">
                <div className="process-left">
                <p className="half-textlarge">
                High-level service blueprint, user flows, and wireframes were created to test, learn, and mitigate risks.
                </p>
                {/* <p>
                   Future-state service blueprint, user Flow Diagrams, and wireframes for the patient mobile app and clinical team back-office system were mapped out.
                   </p> */}
                </div>
                <div className='process-right'>
                
                     <p>
                     Blueprint helped us define both the online and offline experiences, workflows and touchpoint interactions by patients and clinical teams.
                     </p>
                    <h4>Hero flows</h4>  
                    <p>
                    Patients taking a wound image, <br /> 
                    Patients answering recovery questions, <br /> 
                    Cilinicans assessing recovery data, <br /> 
                    Patient-clinical team communication 
                    </p> 

                    <h4>one-time flows</h4>  
                    <p>
                    Setting up a care plan for new patients 
                    </p> 

                    <h4>Alternative flows</h4>  
                    <p>
                    Recovery is not progressing well
                    </p> 

                    <h4>Error flows</h4>  
                    <p>
                    Patient unable to take proper wound images
                    </p> 
                  
                </div>
                
                
                </div>

                {/* <section className="fullspan"> */}
                <div className="image">
                    <Img fluid={props.data.tecneprocess11.childImageSharp.fluid} />
                  </div>
                  {/* </section> */}

              {/* <div className="process">
                <div className="process-left">
                  <h4>We identified a gap</h4>
                </div>
                <div className="process-right">
                  <p>
                    Family member, an under utilised resource; mapping the new ecosystem surrounding the patients
                  </p>
                </div>
              </div> */}

              

              {/* <p className="full-textlarge">
                Apart from the clinical team, we also surrounded the patient with specialised people
                who can provide patients with a holistic support during the entire recovery period.
              </p> */}


              <div className="image">
                <Img fluid={props.data.tecneprocess29.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneprocess14.childImageSharp.fluid} />
              </div>

              <div className="process">
                <div className="process-left">
                <p className="half-textlarge">
                We simulated the redesigned service experience, assessing its human, physical, and digital aspects.
                </p>
                </div>
                <div className="process-right">
                
                {/* <p>
                We wanted to learn about its value, usefulness and usability. 
                </p> */}
                <p>
                Using "test cards," we outlined hypotheses, testing methods, and success criteria to evaluate its value, usefulness, and usability.
                </p>
                   <p>
                   We sought to validate if patients could understand and complete tasks assigned by their clinical team and navigate the app without difficulty.
                   </p>
                   <p>
                   For clinical teams, validation focused on swift access to patient information for informed decision making, ensuring workflow changes improved care without adding unintended risks or burdens.
                   </p>
                </div>
                </div>
              </div>

              <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneprocess30.childImageSharp.fluid} />
              </div>
              </section>

              <div className="process">
                <div className="process-left">
                <p className="half-textlarge">
                Patient journeys are not linear. 
                </p>
                </div>
                <div className="process-right">
                  <p>
                  They encompass various interactions, not all directly tied to hospitals or clinicians.
                  </p>
                <p>
                Beyond the clinical team, we integrated specialized personnel to offer holistic support throughout the entire recovery period.
                </p>
                <p>
                This included professionals in physical health, nutrition, and mental health.
                </p>
                </div>
                </div>

                <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneprocess10.childImageSharp.fluid} />
              </div>
              </section>

              <div className="process">
                <div className="process-left">
                <p className="half-textlarge">
                Accessibility and ease of using technology were critical for adoption by patients.
                </p>
                </div>
                <div className="process-right">
                <p>
                We prioritized larger default font sizes, icons with text, and default voice communication.
                </p>
                <p>
                We also developed a clear guide to ensure users can upload high-quality images of their wounds, 
                including the correct angle needed, and incorporated technology to detect all skin tones.
                </p>
                </div>
                </div>

                <Slider className="image-scroll" {...settings}>
                {/* <Img fluid={props.data.tecneprocess17.childImageSharp.fluid} /> */}
                <Img fluid={props.data.tecneprocess18.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess19.childImageSharp.fluid} />
                </Slider>


                {/* <section className="fullspan">
                <div className="image">
                <Img fluid={props.data.tecneprocess30.childImageSharp.fluid} />
              </div>
              </section> */}

                

              



                
                {/* <p className="full-textlarge">
                  The service blueprint allowed us to identify points in the journey where a
                  digital touchpoint was required to complete a specific task (JTBD) by
                  patients and the clinical teams.
                </p> */}

                {/* <p className="full-textlarge">
                  Detailed User Flow Diagrams & Information Architecture for the patient mobile app and clinical team back-office system was mapped out — happy flow,
                  edge cases, error cases, new user journeys and empty states.
                </p> */}

                {/* <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tecneprocess17.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess18.childImageSharp.fluid} />
                <Img fluid={props.data.tecneprocess19.childImageSharp.fluid} />
                </Slider> */}

                {/* <div className="image">
                  <Img fluid={props.data.tecneprocess13.childImageSharp.fluid} />
                </div> */}


              

              
              {/* <div className="process">
              <div className="process-left">
              <p className="half-textlarge">
                    Next, wireframes of the patients’ mobile app were designed
                    and tested to learn about its value, usefulness and usability.
                  </p>
              </div>
            
                <div className="process-right">
                  <p>
                  We created 'test cards' to outline our hypothesis, how are we going to test, what are we going to measure and its success criteria.
                  We wanted to validate if patients are able to:
                  </p> 
                  <p>
                  Understand the different tasks they are asked to complete their their clinical team.
                  </p>
                  <p>
                  If they are able to access the different flows on the app and are able complete them without any difficulties.
                  </p>
                  <p>
                  Evaluate the app's accessibility standards in terms of fonts, icons and interaction modalities.
                  </p>
                </div>
                </div> */}

                


                {/* <div className="image">
                  <Img fluid={props.data.tecneprocess15.childImageSharp.fluid} />
                </div> */}

                {/* <div className="process-left">
                  <h4>Usability testng</h4>
                </div> */}
                {/* <div className="process-right">
                  <p>
                    We wanted to learn if patients are able to:
                  </p>
                  <ol>
                    <li>Understand what they have to do everyday</li>
                    <li>Able to access the different functionalities</li>
                    <li>Accessibility - colours, fonts, icons, interaction modality</li>
                    <li>Perceive value in using the app to track their recovery</li>
                  </ol>
                </div> */}

                {/* <div className="image">
                  <Img fluid={props.data.tecneprocess16.childImageSharp.fluid} />
                </div> */}
              {/* </div> */}

            </section>


            <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
              <div className="outcome-text-wrapper">
              <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.tecneoutcome54.childImageSharp.fluid} />
              </div>
              </section>

              <h3>
              A smarter way to recover. Reduced wait times? Check. 
              Improved patient and staff experiences? Double check. 
              We have redefined surgical care by
               giving patients a worry-free recovery experience and clinical 
               teams greater insights, enabled by AI. 
              </h3>

              <div className="outcome">
                <div className="outcome-left">
                
                <h3>
                Patients were empowered to play an active role in their own recovery 
                by an easy to use mobile app to quickly, reliably and accurately 
                communicate their wound and recovery status.  
                </h3>
                </div>
                <div className="outcome-right">
                
                <h3>
                The digital patient management system allowed clinical staff and 
                support teams to holistically monitor patient progress remotely 
                and proactively intervene in person if needed.
                </h3>
                </div>
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome52.childImageSharp.fluid} />
              </div>
              <div className="image">
                <Img fluid={props.data.tecneoutcome522.childImageSharp.fluid} />
              </div>

              <div className="outcome">
                <div className="outcome-left">
                <h3>
                Patients experienced a decrease in infection rates & recovery complications. 
                Knowing that their recovery is actively monitored gave them the confidence 
                to live independently and fosters a trustworthy relationship 
                with their care ecosystem.
                </h3>
                
                </div>
                <div className="outcome-right">
                <h3>
                For clinicians, we reduced unnecessary calls, follow-up appointments,
                 and alleviated pressure from unscheduled GP or emergency visits. It 
                 freed up valuable clinical time to focus on patients who require more 
                 attention, ultimately lowering costs for hospitals.
                </h3>
                
                </div>
              </div>

              <p>
              ~~ Our product and service are currently being trialed at Kettering and Northampton General Hospital (NHS, UK).
              </p>

                {/* <span className="project-step-number">03</span>
                <h2>the outcome</h2> */}

                
                
                {/* <h3>
                We have redefined surgical care, giving patients the ultimate 
                recovery experience and clinical teams greater insights, 
                through AI-empowered communications.
                <br />
                <br />
              Our holistic service experience and digital solution enable 
              patients to control their recovery via a mobile app after 
              leaving the hospital.
              </h3> */}

              

              {/* <h3>
              The digital patient management system 
              allows clinical staff and support teams to monitor patient 
              progress remotely and intervene in person if needed.
              </h3> */}

                

                {/* <p>
                  Visit <a href="https://postop.ai">postop.ai</a> to find out
                  more.
                </p> */}
              </div>
            </section>

            <section className="process-section">

              <div className="video">
                <video controls>
                  <source src={video1} type="video/mp4" />
                </video>
              </div>
            </section>


              

              {/* <section className="fullspan">


              <div className="image">
                <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome47.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome48.childImageSharp.fluid} />
              </div>

              </section> */}

              

          </div>
        </div>

        <section className="fullspan">


              <div className="image">
                <Img fluid={props.data.tecneoutcome45.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome46.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome47.childImageSharp.fluid} />
              </div>

              <div className="image">
                <Img fluid={props.data.tecneoutcome48.childImageSharp.fluid} />
              </div>

              </section>


        <div className="project-wrapper">
          <div className="project-content">
            


            <br />
            <p className="full-text">
              Thank you & let's recover remotely with confidence!<span role="img" aria-labelledby="tecneemoji">💪📈</span>
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />


            <div className="full-text">
              <div className="hp-featured__hr-line" />
              <h5>NEXT PROJECT</h5>
            </div>
            <br />


          </div>
        </div>


        <section id="two" className="tiles inner">
          {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
          <div className="tiles__wrapper">
            <a href="/work/service-design-blueprint">
              <div className="tiles__content">
                <div className="tiles__info">
                  {/* <div className="tiles__number">01</div> */}

                  {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                  <div className="tiles__text">
                  Facilitating a Service Blueprint Workshop to provide holistic visibility 
                    across teams, leading to a pivot in the organization's 
                    strategy, business goals, and focus areas.
                  </div>
                  <br />
                  <p className="maisontags-card">Facilitation, Research, Service Design, UX & Business Strategy</p>
                  {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                </div>
              </div>
              <div className="tiles__image">
                <img src={pic04} alt="" />
              </div>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ServiceDesignTecne

export const fluidTecneImage = graphql`
  fragment fluidTecneImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tecnecover: file(
      relativePath: { eq: "projects/service/postop/cover/postop-cover.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_logo: file(
      relativePath: { eq: "projects/service/tecne/postop-logo.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge: file(
      relativePath: { eq: "projects/service/postop/challenge/1.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecnechallenge2: file(
      relativePath: { eq: "projects/service/postop/challenge/2.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess2: file(
      relativePath: { eq: "projects/service/postop/process/2.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess4: file(
      relativePath: { eq: "projects/service/postop/process/4.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess5: file(
      relativePath: { eq: "projects/service/postop/process/5.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess6: file(
      relativePath: { eq: "projects/service/postop/process/6.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess7: file(
      relativePath: { eq: "projects/service/postop/process/7.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess8: file(
      relativePath: { eq: "projects/service/postop/process/8.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess9: file(
      relativePath: { eq: "projects/service/postop/process/9.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess10: file(
      relativePath: { eq: "projects/service/postop/process/10.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess11: file(
      relativePath: { eq: "projects/service/postop/process/11.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess12: file(
      relativePath: { eq: "projects/service/postop/process/12.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess13: file(
      relativePath: { eq: "projects/service/postop/process/13.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess14: file(
      relativePath: { eq: "projects/service/postop/process/14.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess15: file(
      relativePath: { eq: "projects/service/postop/process/15.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess16: file(
      relativePath: { eq: "projects/service/postop/process/16.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess17: file(
      relativePath: { eq: "projects/service/postop/process/17.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess18: file(
      relativePath: { eq: "projects/service/postop/process/18.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess19: file(
      relativePath: { eq: "projects/service/postop/process/19.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess20: file(
      relativePath: { eq: "projects/service/postop/process/20.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess21: file(
      relativePath: { eq: "projects/service/postop/process/21.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess22: file(
      relativePath: { eq: "projects/service/postop/process/22.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess23: file(
      relativePath: { eq: "projects/service/postop/process/23.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess24: file(
      relativePath: { eq: "projects/service/postop/process/24.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess25: file(
      relativePath: { eq: "projects/service/postop/process/25.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess26: file(
      relativePath: { eq: "projects/service/postop/process/26.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess27: file(
      relativePath: { eq: "projects/service/postop/process/27.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess28: file(
      relativePath: { eq: "projects/service/postop/process/28.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess29: file(
      relativePath: { eq: "projects/service/postop/process/29.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess30: file(
      relativePath: { eq: "projects/service/postop/process/30.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess31: file(
      relativePath: { eq: "projects/service/tecne/process/31.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess32: file(
      relativePath: { eq: "projects/service/tecne/process/32.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess33: file(
      relativePath: { eq: "projects/service/tecne/process/33.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess34: file(
      relativePath: { eq: "projects/service/tecne/process/34.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess35: file(
      relativePath: { eq: "projects/service/tecne/process/35.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess36: file(
      relativePath: { eq: "projects/service/tecne/process/36.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess37: file(
      relativePath: { eq: "projects/service/tecne/process/37.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess38: file(
      relativePath: { eq: "projects/service/tecne/process/38.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess39: file(
      relativePath: { eq: "projects/service/tecne/process/39.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess40: file(
      relativePath: { eq: "projects/service/tecne/process/40.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess41: file(
      relativePath: { eq: "projects/service/tecne/process/41.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess42: file(
      relativePath: { eq: "projects/service/tecne/process/42.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess43: file(
      relativePath: { eq: "projects/service/tecne/process/43.png" }
    ) {
      ...fluidTecneImage
    }
    tecneprocess44: file(
      relativePath: { eq: "projects/service/tecne/process/44.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome45: file(
      relativePath: { eq: "projects/service/postop/outcome/45.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome46: file(
      relativePath: { eq: "projects/service/postop/outcome/46.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome47: file(
      relativePath: { eq: "projects/service/postop/outcome/47.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome48: file(
      relativePath: { eq: "projects/service/postop/outcome/48.jpg" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome49: file(
      relativePath: { eq: "projects/service/postop/outcome/49.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome50: file(
      relativePath: { eq: "projects/service/postop/outcome/50.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome51: file(
      relativePath: { eq: "projects/service/postop/outcome/51.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome52: file(
      relativePath: { eq: "projects/service/postop/outcome/52.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome522: file(
      relativePath: { eq: "projects/service/postop/outcome/522.png" }
    ) {
      ...fluidTecneImage
    }
    tecneoutcome53: file(
      relativePath: { eq: "projects/service/postop/outcome/53.png" }
    ) {
      ...fluidTecneImage
    }
      tecneoutcome54: file(
      relativePath: { eq: "projects/service/postop/outcome/54.jpg" }
    ) {
      ...fluidTecneImage
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidTecneImage
    }
    tecne_process: file(
      relativePath: { eq: "projects/process/tecne-process.png" }
    ) {
      ...fluidTecneImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidTecneImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidTecneImage
    }
  }
`
